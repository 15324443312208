<div class="filter-wrapper">
    @if (filtersVisible()) {
        <div class="filter-wrapper-item">
            @for (configItem of config; track configItem.id) {
                @switch (configItem.inputType) {
                    @case (FilterInputType.Dropdown) {
                        <div class="grid-col">
                            <div class="form-group">
                                <label class="k-form-field" [for]="configItem.id">
                                    <span>
                                        {{ configItem.label | translate }}
                                    </span>
                                </label>
                                <kendo-dropdownlist
                                    [id]="configItem.id"
                                    [data]="configItem.dropDownData"
                                    [textField]="configItem.textField"
                                    [valueField]="configItem.valueField"
                                    [valuePrimitive]="true"
                                    [defaultItem]="{ id: null, name: 'qrCode.selectItem' | translate }"
                                    [ngModel]="configItem.value"
                                    (ngModelChange)="configItem.valueChange($event)"
                                    [filterable]="true"
                                    [kendoDropDownFilter]="filterSettings"
                                >
                                    @if (configItem.id === 'areaId') {
                                        <ng-template kendoDropDownListItemTemplate let-item kendoDropDownListValueTemplate>
                                            @if (item.id) {
                                                <span [title]="item.name">
                                                    {{ item.shortName }}
                                                </span>
                                            } @else {
                                                {{ item.name }}
                                            }
                                        </ng-template>
                                    }
                                </kendo-dropdownlist>
                            </div>
                        </div>
                    }
                    @case (FilterInputType.Textbox) {
                        <div class="grid-col">
                            <div class="form-group">
                                <label class="k-form-field" [for]="configItem.id">
                                    <span>
                                        {{ configItem.label | translate }}
                                    </span>
                                </label>
                                <input
                                    [id]="configItem.id"
                                    type="text"
                                    class="k-textbox"
                                    [ngModel]="configItem.value"
                                    (ngModelChange)="configItem.valueChange($event)"
                                />
                            </div>
                        </div>
                    }
                }
            }
            <button kendoButton type="button" (click)="clearFilters.emit()">
                {{ 'gridFilters.clearFilters' | translate }}
            </button>
        </div>
    }
    <div class="filter-wrapper-item">
        <div class="grid-col">
            <button kendoButton type="button" (click)="onShowFilters()">
                @if (!filtersVisible()) {
                    {{ 'gridFilters.showFilters' | translate }}
                } @else {
                    {{ 'gridFilters.hideFilters' | translate }}
                }
            </button>
            @if (!filtersVisible()) {
                <span>
                    @if (activeFilterCount && activeFilterCount === 1) {
                        {{ activeFilterCount }} {{ 'gridFilters.activeFilter' | translate }}
                        <mat-icon (click)="clearFilters.emit()" class="filter-delete-icon" svgIcon="icon_delete"></mat-icon>
                    }
                    @if (activeFilterCount && activeFilterCount > 1) {
                        {{ activeFilterCount }} {{ 'gridFilters.activeFilters' | translate }}
                        <mat-icon (click)="clearFilters.emit()" class="filter-delete-icon" svgIcon="icon_delete"></mat-icon>
                    }
                </span>
            }
        </div>
        <ng-content select="[grid]" />
    </div>
</div>
