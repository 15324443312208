import { TemplateRef } from '@angular/core';

export interface MultiEditSelection {
  id: any;
  sel: boolean;
  ind: boolean;
}

export interface EnumModel {
  id?: number;
  name?: string;
}

export enum URL_ACTION {
  MULTI_EDIT = 'multiEdit',
  DETAIL = 'detail',
  NEW = 'new',
}

export enum PRODUCT_MODEL_TYPE {
  PANEL = 1,
  OVERVIEW = 2,
  TOUCH_CUE_PANEL = 6,
  CONTROLLER = 7,
}

export interface GridFilterConfig {
  id: string;
  label: string;
  value: number | string;
  dropDownData: any[];
  textField: string;
  valueField: string;
  inputType: GridFilterInputType;
  valueChange(value: number | string): void;
}

export enum GridFilterInputType {
  Dropdown = 'dropdown',
  Textbox = 'textBox',
}
