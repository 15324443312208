import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownFilterSettings, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { GridFilterConfig, GridFilterInputType } from '../../models';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-grid-filter',
  standalone: true,
  templateUrl: './grid-filter.component.html',
  styleUrl: './grid-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, FormsModule, DropDownListModule, ButtonModule, MatIcon],
})
export class GridFilterComponent {
  protected readonly FilterInputType = GridFilterInputType;
  @Input({ required: true }) config: GridFilterConfig[] = [];
  @Input({ required: true }) activeFilterCount: number;
  @Output() clearFilters = new EventEmitter();
  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public filtersVisible = signal<boolean>(false);

  public onShowFilters() {
    this.filtersVisible.set(!this.filtersVisible());
  }
}
