import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, map, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  public showDropdown$ = new BehaviorSubject<boolean>(false);

  constructor(private breakpointObs: BreakpointObserver) {}

  observeMobile(): Observable<BreakpointState> {
    return this.breakpointObs.observe('(max-width: 576px)');
  }

  observeTabletPortrait(): Observable<BreakpointState> {
    return this.breakpointObs.observe('(max-width: 768px)');
  }

  observeTablet(): Observable<BreakpointState> {
    return this.breakpointObs.observe('(max-width: 992px)');
  }

  observeLaptop(): Observable<BreakpointState> {
    return this.breakpointObs.observe('(max-width: 1200px)');
  }

  isMobile() {
    return this.observeMobile().pipe(map((x) => x.matches));
  }

  isNotMobile() {
    return this.observeMobile().pipe(map((result) => !result.matches));
  }

  isTabletPortrait() {
    return this.observeTabletPortrait().pipe(map((x) => x.matches));
  }

  isTablet() {
    return this.observeTablet().pipe(map((x) => x.matches));
  }

  isLaptop() {
    return this.observeLaptop().pipe(map((x) => x.matches));
  }

  toggleDropdown(): void {
    this.showDropdown$.next(!this.showDropdown$.value);
  }
}
