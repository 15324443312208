/* src/app/shared/components/grid-filter/grid-filter.component.scss */
:host {
  display: block;
}
:host .filter-wrapper {
  display: flex;
  gap: 1rem;
}
:host .filter-wrapper-item {
  width: 100%;
  flex: 0 0 20em;
  transition: width 2s;
}
:host .filter-delete-icon {
  cursor: pointer;
}
/*# sourceMappingURL=grid-filter.component.css.map */
