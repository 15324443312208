import { Injectable } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as icons from '../../icons/data/admin-icon.model';

@Injectable({
  providedIn: 'root',
})
export class AppIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  icons = [
    icons.iconMaximize,
    icons.iconMinimize,
    icons.iconAdd,
    icons.iconArrow,
    icons.iconBlinkOff,
    icons.iconBlinkOn,
    icons.iconCopy,
    icons.iconDelete,
    icons.iconEdit,
    icons.iconExport,
    icons.iconHelp,
    icons.iconImport,
    icons.iconIndicator,
    icons.iconIndicators,
    icons.iconRefresh,
    icons.iconSettings,
    icons.iconView,
    icons.iconWait,
    icons.iconSendEmail,
    icons.iconAccount,
    icons.iconUnregister,
    icons.iconMenuAccounts,
    icons.iconMenuActiveDirectories,
    icons.iconMenuAdministration,
    icons.iconMenuAnalytics,
    icons.iconMenuAreas,
    icons.iconMenuBackupRestore,
    icons.iconMenuCalendar,
    icons.iconMenuFirmware,
    icons.iconMenuIdentification,
    icons.iconMenuLanguages,
    icons.iconMenuPictures,
    icons.iconMenuProperties,
    icons.iconMenuResources,
    icons.iconMenuSupport,
    icons.iconMenuServers,
    icons.iconMenuSettings,
    icons.iconMenuSetup,
    icons.iconMenuSystem,
    icons.iconMenuTimetables,
    icons.iconMenuTimeSettings,
    icons.iconMenuUnits,
    icons.iconMenuUsers,
    icons.iconMenuSubjects,
    icons.iconMenuEmailAccount,
    icons.iconMenuSensor,
    icons.iconMenuAnalyticsBasicOverview,
    icons.iconMenuAnalyticsTemplate,
    icons.iconCopyLink,
    icons.iconDownload,
    icons.iconToken,
    icons.iconMenuAnalytics,
    icons.iconMenuAnalyticsBasicOverview,
    icons.iconMenuAnalyticsTemplate,
    icons.iconHome,
    icons.iconMenuServerOffice365,
    icons.iconMenuServerExchangeAndAd,
    icons.iconMenuServiceAccounts,
    icons.iconMenuConnectionAgents,
    icons.iconAddFolder,
    icons.iconAddFile,
    icons.iconArrowUp,
    icons.iconArrowDown,
    icons.iconTest,
    icons.iconDatabase,
    icons.iconLogoAssist,
    icons.iconMail,
    icons.iconAnalytics,
    icons.iconAssist,
    icons.iconUnits,
    icons.iconSmartBuilding,
    icons.iconResources,
    icons.iconUser,
    icons.iconQrCode,
    icons.iconNewTab,
    icons.iconSetValues,
    icons.iconMenuDots,
    icons.iconLink,
    icons.iconCalendar,
    icons.iconMatterport,
    icons.iconMatterportRed,
  ];

  init() {
    this.icons.forEach((x) => {
      this.matIconRegistry.addSvgIconLiteral(x.name, this.domSanitizer.bypassSecurityTrustHtml(x.data));
      // this.matIconRegistry.addSvgIcon(
      //     x,
      //     this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/icon_" + x + ".svg")
      // );
    });

    this.matIconRegistry.addSvgIcon('logo', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/logo.svg'));
  }
}
